@import "~@/erp/styles/variables/variables.scss";























































































































































































































































































































































































































































































































































































































































































































































































































.delivery-period {
  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;

    .form-cont {
      .el-form-item__content {
        width: 180px;
      }
    }

    .select-col {
      width: 898px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .el-form-item {
        margin-right: 0;

        .el-button {
          border-radius: 6px;
        }
      }
    }

    .tip-el-col {
      margin-top: 20px;
      color: #999;

      .required {
        color: #ff4d4f;
      }
    }

    .specifications-box {
      .el-form-item {
        margin-right: 30px;

        .el-input__inner {
          width: 180px !important;
          height: 32px !important;
        }
      }

      .symbol {
        display: inline-block;
        margin-right: 15px !important;
        margin-left: -15px;
        margin-top: 6px;

        &.mat32 {
          margin-top: 38px;
        }
      }
    }
  }

  .price {
    &.el-form-item {
      margin: 0;

      .el-form-item__content {
        display: flex;
        align-items: center;

        .el-input {
          width: 100px;

          .el-input__inner {
            text-align: center;
          }
        }

        .el-tooltip {
          margin: 0 10px;
        }

        .el-form-item__error {
          display: none;
        }
      }

      &.day {
        .el-form-item__content {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  .mat32 {
    display: inline-block;
    margin-top: 32px;
  }
}

@import "~@/erp/styles/variables/variables.scss";















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
  }
}

::v-deep {
  .el-cascader {
    width: 100%;
  }

  .test {
    .el-input__inner {
      padding: 0 60px 0 15px;
    }
  }

  .el-textarea__inner {
    padding: 5px 50px 5px 10px;
  }
}

.keyWord-tag {
  margin: 0 10px 10px 0 !important;
}

@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































































































































































































































































































































































.calcu-p {
  font-size: 12px;
  color: #999;
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.el-dialog-div {
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
}
::v-deep {
  .el-dialog-div {
    .el-form {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
}

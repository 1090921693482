@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-table__header-wrapper .el-table__cell {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.special-header {
  position: relative;

  span {
    display: inline-block;
    width: 50%;
    text-align: right;

    &.special-header--right {
      text-align: left;

      &::after {
        content: '';
        display: inline-block;
        width: 400px;
        height: 1px;
        background-color: #ebeef5;
        /* background-color: red; */
        position: relative;
        left: -124px;
        transform: rotate(11deg);
      }
    }
  }
}

.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
    margin-left: 10px;
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
  }
}

.draggable-row {
  > div {
    text-align: left;
  }
}

::v-deep {
  .el-tag {
    margin-top: 5px;
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .saveTagInput-row {
    .el-input--small .el-input__inner {
      height: 32px !important;
    }
  }

  .down-tag {
    font-size: 12px;
    margin-top: 10px;
  }
}

.wrapper-box {
  display: flex;
  justify-content: center;
}

.sort {
  text-align: center;
  cursor: all-scroll;

  i {
    font-size: 18px;
  }
}

.iconfont {
  cursor: pointer;
  font-size: 14px;
}

.icon-xiajia {
  color: #1890ff;
}

.wrapper-box {
  .wrapper-add {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px dotted #c0cbda;
    border-radius: 3px;
    cursor: pointer;

    .el-row {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        font-size: 12px;
        color: #9e9e9e;
      }

      i {
        color: #9e9e9e;
      }
    }
  }
}

.content {
  height: 100%;
  border: 1px solid #e4e7ed;
  border-radius: 3px;

  .content-head {
    height: 50px;
    line-height: 50px;
    background-color: #f2f2f2;
    width: 100%;
    padding-left: 10px;
  }
}

::v-deep {
  .el-table {
    .el-table__body td {
      height: 86px;

      .el-form-item {
        margin: 18px 0;
      }
    }
  }
}

::v-deep {
  .el-input {
    width: 180px !important;
  }
}

.el-icon-delete {
  color: red;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.col-label {
  margin-bottom: 5px;

  span.required {
    color: red;
  }
}

.btn-wrapper {
  margin-top: 15px;
  text-align: center;
}

.el-icon-copy-document {
  cursor: pointer;
}

.print-block {
  border-bottom: 1px dashed #d7d7d7;
  margin-top: 20px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.color555 {
  color: #555555;
  font-size: 13px;
}

.radio-item-wrapper {
  ::v-deep {
    .el-radio__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 155px;
      display: inline-block;
    }
  }
}

.canvas-icon {
  display: flex;
  align-items: center;
  line-height: 36px;

  i {
    font-size: 18px;
    margin: 0 5px;
  }

  i:hover {
    color: #409eff;
  }
}

.rendering-desc {
  padding: 13px 0 0 10px;
  flex: 1;
  width: 0;

  ::v-deep .el-checkbox {
    display: flex;
    align-items: center;

    .el-checkbox__label {
      font-size: 14px;
      flex: 1;
      width: 0;
      word-break: break-all;
      white-space: normal;
    }

    .el-checkbox__input {
      margin-top: 2px;
    }
  }
}

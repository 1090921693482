@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .right-content-row {
    height: 100%;
    padding: 20px;
    border-radius: 3px;
    padding-top: 0;
  }
}

.hideAdd {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}

.get-method {
  float: left;
  margin-top: 36px;
  line-height: 32px;
  font-size: 14px;
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
}

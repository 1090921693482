@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































.dialog-div {
  max-height: 70vh;
  overflow: hidden;
}

.container {
  height: 55vh;
  overflow: hidden;
  overflow-y: auto;
}

::v-deep {
  .dialog-div {
    .w-el-row {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
}

.supplierChoose {
  line-height: 30px;
  background-color: #f4f4ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 3px;

  .el-icon-error,
  .el-icon-star-on,
  .el-icon-star-off:hover {
    cursor: pointer;
  }

  .iconbox {
    display: flex;
    align-items: center;
  }
}

.chooseBox {
  overflow: hidden;
  overflow-y: scroll;
  height: 510px;
}

@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































































































































































































































































































































































































.print-area {
  margin: 0 0 20px 0;
  height: 582px;
  width: 582px;
  border: 1px solid #ededed;
  position: relative;

  .opera {
    position: absolute;
    bottom: 10px;
    right: 10px;
    box-shadow: 1px 1px 5px #ccc;
    background: white;

    i {
      font-size: 20px;
      cursor: pointer;
      display: block;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
    }

    i.reset {
      font-size: 16px;
    }

    i:hover {
      color: #409eff;
    }
  }

  .switch {
    margin-top: 10px;
    width: 580px;
    text-align: center;
    color: #000;

    i {
      cursor: pointer;
      margin: 0 10px;
    }

    i:hover {
      color: #409eff;
    }
  }
}

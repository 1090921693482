@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































































































































































































::v-deep {
  .el-radio {
    line-height: 32px;
  }
}
